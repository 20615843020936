<template>
    <vx-card :title="'View Stock Transfer Order Picking'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <!-- New layout, 1 baris 4 kolom -->
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th>STO Number</th>
                            <th>Destination Warehouse</th>
                            <th>Est. Delivery Date (ETD)</th>
                            <th>Est. Time Arrived (ETA)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="sto_plan_code" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="selectedDestWarehouse" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="formatDate(this.reqDeliveryDate)" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="formatDate(this.eta)" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table class="table table-bordered" style="width:100%">
                    <thead class="vs-table--thead">
                        <tr>
                            <th rowspan="2" style="vertical-align: middle; text-align:center;">SKU Code</th>
                            <th rowspan="2" style="vertical-align: middle; text-align:center;">SKU Name</th>
                            <th rowspan="2" style="vertical-align: middle; text-align:center;">HU</th>
                            <th rowspan="2" style="vertical-align: middle; text-align:center;">Qty</th>
                            <th colspan="3" style="vertical-align: middle; text-align:center;">Suggestion</th>
                        </tr>
                        <tr>
                            <th style="vertical-align: middle; text-align:center;">Storage Area</th>
                            <th style="vertical-align: middle; text-align:center;">Batch Number</th>
                            <th style="vertical-align: middle; text-align:center;">Expired Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(dt, index) in dataLines" :key="index" style="padding-bottom: 5px">
                            <td class="td vs-table--td" style="vertical-align: middle; text-align:center;">
                                {{ dt.sku_code }}
                            </td>
                            <td class="td vs-table--td" style="vertical-align: middle; text-align:center;">
                                {{ dt.item_name }}
                            </td>
                            <td class="td vs-table--td" style="vertical-align: middle; text-align:center;">
                                {{ dt.unit }}
                            </td>
                            <td class="td vs-table--td" style="vertical-align: middle; text-align:center;">
                                {{ dt.amount }}
                            </td>
                            <td class="td vs-table--td" style="vertical-align: middle; text-align:center;">
                                {{ dt.storage_code + " - " + dt.storage_name }}
                            </td>
                            <td class="td vs-table--td" style="vertical-align: middle; text-align:center;">
                                {{ dt.batch }}
                            </td>
                            <td class="td vs-table--td" style="vertical-align: middle; text-align:center;">
                                {{ formatDate(dt.expired_date) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    right: 0%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";

export default {
    components: {
    },
    data() {
        return {
            id: null,
            readonly: true,
            sto_plan_code: null,
            picking_code: null,
            selectedDestWarehouse: null,
            reqDeliveryDate: null,
            eta: null,
            dataLines: [],
        }
    },
    mounted() {
        var now = moment()
        now.add(1, 'days')
        this.id = this.$route.params.id;
        if (this.id) {
            console.log(this.id)
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/outbound/sto-processing/" + this.id)
                .then((resp) => {
                    console.log(resp)
                    if (resp.code == 200) {
                        this.dataLines = resp.data.picking_lines;
                        this.sto_plan_code = resp.data.headers.sto_plan_code;
                        this.picking_code = resp.data.headers.picking_code;
                        this.selectedDestWarehouse = resp.data.headers.dest_warehouse_code + " - " + resp.data.headers.dest_warehouse_name;
                        this.reqDeliveryDate = resp.data.headers.req_delivery_date;
                        this.eta = resp.data.headers.eta;
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        handleBack() {
            this.$router.push({
                name: "outbound.stock-transfer-order-processing",
            });
        },
        openTab(uri) {
            window.open(uri)
        }
    },
    watch: {
    },
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>